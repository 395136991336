import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'
import { HomePage, ProjectSwitcher } from 'pages'
import { Vd, Offers, Poi, SimpleProject } from 'containers'
import App from 'App'
import { TestSchema } from 'components/TestSchema'
import { TestSchema2 } from 'components/TestSchema2'
import { TestSchema3 } from 'components/TestSchema3'
import { TestSchema4 } from 'components/TestSchema4'
import { TestSchema5 } from 'components/TestSchema5'
import { userLoader } from './loaders'

const ProtectedRoute = () => {
  const { isAuthenticated } = useAuth()
  return isAuthenticated ? <Outlet /> : <Navigate to="/" replace={false} />
}

export const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    children: [
      { path: '/', element: <HomePage /> },
      { path: '/infra-schema/1', element: <TestSchema /> },

      { path: '/infra-schema/2', element: <TestSchema2 /> },

      { path: '/infra-schema/3', element: <TestSchema3 /> },

      { path: '/infra-schema/4', element: <TestSchema4 /> },
      { path: '/infra-schema/5', element: <TestSchema5 /> },

      {
        path: '*',
        element: <ProtectedRoute />,
        children: [
          { path: 'projects', element: <ProjectSwitcher /> },
          { path: 'vd', element: <Vd /> },
          {
            path: 'offers',
            element: <Offers />,
            loader: userLoader,
          },
          {
            path: 'poi',
            element: <Poi />,
            loader: userLoader,
          },
          {
            path: 'simple-project',
            element: <Outlet />,
            children: [
              { path: '', element: <Navigate to="/projects" /> },
              {
                path: ':projectId',
                element: <SimpleProject />,
                loader: userLoader,
              },
            ],
          },
        ],
      },
    ],
  },
])
