import { captureException } from '@sentry/react'
import imageCompression, { Options } from 'browser-image-compression'
import { SourceLocation } from 'graphql'
import jsCookies from 'js-cookie'
import { baseMaps } from 'pages/Map/olMapLayers'
import { acceptableExtensions } from 'shared/constants'

/**
 * Logs an error with detailed information in a structured table format to the console
 *
 * @param {('GraphQlError' | 'NetworkError' | 'UnknownError')} type - The category of the error.
 * @param {(string | Error)} message - The error message or an Error object containing the message.
 * @param {string[]} [tags] - Optional array of tags for categorizing or highlighting specific aspects of the error.
 * @param {readonly SourceLocation[]} [locations] - Optional array of locations, typically used in GraphQL errors,
 *   to indicate where in the query the error occurred.
 * @param {readonly (string | number)[]} [path] - Optional path array that specifies the location of the failing
 *   field in the response, often used in GraphQL errors.
 *
 * @example
 * // Log a simple network error with tags.
 * logError('NetworkError', new Error('Failed to fetch data'), ['critical', 'api']);
 *
 * @example
 * // Log a GraphQL error with detailed location and path information.
 * logError(
 *   'GraphQlError',
 *   'Invalid query syntax',
 *   ['query', 'validation'],
 *   [{ line: 5, column: 16 }],
 *   ['data', 'user', 0, 'name']
 * );
 */
export const logError = (
  type:
    | 'GraphQlError'
    | 'NetworkError'
    | 'UnknownError'
    | 'LogicError'
    | 'MapError'
    | 'FormValidationError',
  message: string | Error,
  tags?: string[],
  locations?: readonly SourceLocation[] | undefined,
  path?: readonly (string | number)[] | undefined
): void => {
  const messageText = message instanceof Error ? message.message : message
  const errorDetails = {
    Type: type,
    Message: messageText,
    Locations: locations ? JSON.stringify(locations) : 'N/A',
    Path: path ? JSON.stringify(path) : 'N/A',
    Tags: tags?.join(', ') || 'N/A',
  }

  console.error(`Error [${type}] - See details in the table below`)
  console.table(errorDetails)

  if (tags) {
    console.log(`Tags: %c${tags.join(', ')}`, 'color: blue; font-weight: bold;')
  }
}
/**
 * check if an object is empty
 * @param obj - The object to check against
 * @returns {boolean}
 */
export function isEmptyObject(obj) {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  )
}

/**
 * get the user token stored in cookies
 * @returns {string} full user token for authentication
 */
export const getFullToken = (): string | null => {
  const token = jsCookies.get('access_token')
  return token ? `Bearer ${token}` : null
}

/**
 * Determines the truthiness of a value based on its type and content.
 *
 * @param value - The value to evaluate for truthiness.
 * @returns `true` if the value is truthy, `false` otherwise.
 */
export const isTruthy = (value: any): boolean => {
  if (typeof value === 'string') {
    return value.trim() !== ''
  } else if (Array.isArray(value)) {
    return value.length > 0
  } else if (typeof value === 'object') {
    return Object.keys(value).length > 0
  } else {
    return !!value
  }
}

export const getSelectedBaseMap = (label) =>
  baseMaps.find((basemap) => basemap.label === label)

export const isSimpleProject = location.pathname.includes('simple-project')

/**
 * Extracts the filename from a URL.
 * @param {string} url - The URL from which to extract the filename.
 * @returns {string} The extracted filename.
 */
export function extractFilename(url: string) {
  try {
    const urlObj = new URL(url, 'http://example.com')
    const pathname = urlObj.pathname
    return pathname.split('/').pop().split('#')[0].split('?')[0]
  } catch (error) {
    // If URL constructor fails, handle it as a simple path
    return url.split('/').pop().split('#')[0].split('?')[0]
  }
}

export const isProdEnv = process.env.REACT_APP_DEPLOYMENT === 'PRODUCTION'

export const raiseSentryError = (
  error: any,
  context?: object,
  tags?: object
) => {
  if (isProdEnv) {
    captureException(error, {
      contexts: { ...(context ?? {}) },
      tags: { ...(tags ?? {}) },
    })
  } else {
    logError('UnknownError', error)
  }
}

export const compressImg = async (file) => {
  const fileExtension = file?.name?.split('.').pop().toLowerCase()

  if (acceptableExtensions.includes(fileExtension)) {
    const options: Options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1500,
      useWebWorker: true,
      preserveExif: true,
    }
    try {
      const compressedFile = (await imageCompression(file, options)) as any
      compressedFile.name = file.name
      return compressedFile
    } catch (error) {
      raiseSentryError(error, {
        'file-compression': {
          originalFile: file,
        },
      })
      logError('LogicError', error, ['file compression error'])
      return file
    }
  } else {
    return file
  }
}
