import React, { useState } from 'react'
import { transform } from 'ol/proj'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { TextField } from '@material-ui/core'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import { useSelector, useDispatch } from 'react-redux'
import { RasedMap } from 'pages/Map/RasedMap'
import { RootState } from 'store'
import { gqlClient } from 'lib/graphql'
import { GET_SIMPLE_RECORD } from 'global'
import { logError } from 'utils'
import { updateSelectedRecord } from 'store/layers'
import { toggleFormDrawer } from 'store/layout'
import toast from 'react-hot-toast'
import { useTranslation } from '@flint/locales'
import { useStyle } from './RecordSearch.style'

interface Props {
  mapRef?: React.MutableRefObject<RasedMap>
}

export const RecordSearch = ({ mapRef }: Props) => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [recordId, setRecordId] = useState('')
  const { selectedListItem } = useSelector((state: RootState) => state.layout)

  const handleSearch = async () => {
    if (!selectedListItem?.id) {
      toast.error(t('no-selected-task'), {
        duration: 2000,
      })
      return
    }
    if (!recordId) {
      toast.error(t('please-enter-record-id'), {
        duration: 2000,
      })
      return
    }

    try {
      const { data } = await gqlClient.query({
        query: GET_SIMPLE_RECORD,
        variables: {
          pk: recordId,
          taskId: selectedListItem?.id,
        },
      })

      if (data?.records?.data?.[0]) {
        const record = data.records.data[0]
        const selectedRecord = {
          id: record.id,
          data: {
            ...record?.data,
            ...record?.data?.rased,
          },
        }

        // Update selected record in store
        dispatch(updateSelectedRecord(selectedRecord))

        // Open the form drawer
        dispatch(toggleFormDrawer(true))

        // Zoom to and highlight the record on map
        if (mapRef?.current && record.geometry) {
          const coordinates = record.geometry.geometries?.[0]?.coordinates
          if (coordinates) {
            // Transform coordinates for center
            const transformedCoords = transform(
              coordinates,
              'EPSG:4326',
              'EPSG:3857'
            )

            // Set map center and zoom
            mapRef.current.getView().setCenter(transformedCoords)
            mapRef.current.getView().setZoom(18)

            // Create feature matching the expected format
            const feature = {
              type: 'Feature',
              id: record.id,
              geometry: {
                type: 'GeometryCollection',
                geometries: [
                  {
                    type: 'Point',
                    coordinates: transformedCoords,
                  },
                ],
              },
              geometry_name: 'geometry',
            }

            // Use RasedMap's built-in highlight feature
            mapRef.current.highlightFeature(feature as any)
          }
        }

        // Close search
        setIsSearchOpen(false)
        setRecordId('')
      } else {
        toast.error(t('no-record-found'), {
          duration: 2000,
        })
      }
    } catch (error) {
      logError('NetworkError', error, ['simple project', 'searching record'])
    }
  }

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.searchIconBtn}
        onClick={() => setIsSearchOpen(!isSearchOpen)}
      >
        <SearchIcon color="primary" />
      </IconButton>

      {isSearchOpen && (
        <>
          <TextField
            className={classes.searchInput}
            id="search-task"
            variant="outlined"
            placeholder="البحث عن سجل"
            size="small"
            value={recordId}
            onChange={(e) => setRecordId(e.target.value)}
          />
          <IconButton className={classes.sendIconBtn} onClick={handleSearch}>
            <AutorenewIcon
              color="primary"
              style={{ transform: 'rotate(180deg)' }}
            />
          </IconButton>
        </>
      )}
    </div>
  )
}
