import { FormDrawer } from 'components/AppDrawers'
import { LayersControlPanel } from 'components/LayersControlPanel'
import { SimpleProjectRecordForm } from 'components/RecordForm'
import BaseMap from 'pages/Map/BaseMap'
import { RasedMap } from 'pages/Map/RasedMap'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { updateDrawingTools, updateSelectedRecord } from 'store/layers'
import AddLocationIcon from '@material-ui/icons/AddLocation'
import { getTaskWmsLayerData } from 'utils'
import { DrawingTools } from 'components/DrawingTools'
import { MapFab } from 'components/MapFab'
import { RecordSearch } from 'components/RecordSearch'
import { Box } from '@material-ui/core'

export const SimpleProjectMap = () => {
  const mapRef = useRef<RasedMap | null>(null)
  const dispatch = useDispatch()

  const { selectedListItem } = useSelector((state: RootState) => state.layout)
  const { selectedFeature } = useSelector((state: RootState) => state.tasks)
  const { drawingTools } = useSelector((state: RootState) => state.layers)

  const { openFlag } = drawingTools

  useEffect(() => {
    if (selectedFeature) {
      mapRef?.current.handleSelectedFeature(selectedFeature)
    }
  }, [selectedFeature])

  useEffect(() => {
    if (selectedListItem) {
      mapRef?.current.zoomToTaskBoundaries(selectedListItem)

      const taskWmsLayers = getTaskWmsLayerData(selectedListItem, 'simple')

      // add base wms layer
      mapRef?.current.addWmsLayers(taskWmsLayers.filter((l) => l.type === 'pa'))

      // add clickable wms data layer
      mapRef?.current.addClickableWmsLayer(
        taskWmsLayers.find((l) => l.type === 'points')
      )

      if (selectedListItem?.gpxUrls && selectedListItem?.gpxUrls.length) {
        selectedListItem.gpxUrls?.forEach((url) => {
          mapRef?.current.addGpxLayer(url)
        })
      }
    }

    return () => {
      // reset layers
      mapRef?.current.resetLayers()
    }
  }, [selectedListItem])

  // Disable map interactions while drawing
  useEffect(() => {
    if (mapRef.current && drawingTools.openFlag) {
      // Remove click interaction when drawing starts
      mapRef.current.removeWmsClickInteraction()
    } else {
      // Add click interaction when drawing ends
      mapRef.current.addWmsClickInteraction('points')
    }
  }, [drawingTools.openFlag])

  return (
    <>
      <BaseMap mapRef={mapRef} />
      {!openFlag && (
        <MapFab
          icon={<AddLocationIcon />}
          onClick={() => {
            dispatch(updateSelectedRecord({}))
            dispatch(updateDrawingTools({ ...drawingTools, openFlag: true }))
          }}
        />
      )}
      <div
        style={{
          position: 'absolute',
          top: 60,
          zIndex: 1000,
          right: 1,
          color: '#333',
        }}
      >
        <DrawingTools map={mapRef.current} />
        <FormDrawer form={<SimpleProjectRecordForm />} />
        <Box display="flex" flexDirection="column">
          <LayersControlPanel map={mapRef?.current} />
          {selectedListItem?.id && <RecordSearch mapRef={mapRef} />}
        </Box>
      </div>
    </>
  )
}
