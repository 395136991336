import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => ({
  root: {
    // position: 'absolute',
    // top: 0,
    // left: 0,
    margin: '2px 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainControls: {
    display: 'flex',
    flexDirection: 'row',
  },
  searchIconBtn: {
    background: 'white',
    padding: '.2rem',
    borderRadius: '10px',
    '&:hover': {
      background: 'white',
    },
  },
  sendIconBtn: {
    background: 'white',
    padding: '.2rem',
    borderRadius: '10px',
    '&:hover': {
      background: 'white',
    },
  },
  searchInput: {
    backgroundColor: 'white',
    margin: '0px 6px',
    width: '160px',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(180deg)',
    },
    '100%': {
      transform: 'rotate(540deg)',
    },
  },
}))
